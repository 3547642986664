import * as React from 'react';
import { sortAndGroupList } from '../util';
import { FlexBox, ListHeader, MasterContainer } from '../styles';
import GetCompleteList from '../components/CompleteList';
import { GroupIcon, CollapseIcon } from '../components/ButtonAndIcon';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';

function Master({
  list,
  group,
  postApi,
  timerOn,
  setTimerOn,
  minimize,
  hideDetail,
  addTaskToList,
  deleteItem,
  setItem,
  minimizeList,
  hideListDetail,
  groupTask,
  saveList,
  showForm,
  setShowForm,
}) {
  const navigate = useNavigate();
  const initFilter = { name: '' }
  const [filter, setFilter] = React.useState(initFilter);
  
  const handleChange = (f, e) => {
    setFilter({ ...filter, [f]: e.target.value });
  }

  const allList = sortAndGroupList('all', list, group.all, { name: filter.name });
  const footerProps = { addTaskToList, navigate, minimizeList, minimize, postApi, showForm, setShowForm };

  return (
    <div className="app">
      <ListHeader id="all" onClick={minimizeList} bg="#028316" color="white">
        <GroupIcon id="all" grouped={group.all} onClick={groupTask} />
          All's todo
          <div>
           <input style={{ fontSize: '15px', padding: '5px' }} id="title" placeholder="Task title" value={filter.name} onChange={(e) => handleChange('title', e)} />
            <span style={{ marginLeft: '10px' }} onClick={() => setFilter(initFilter)}>clear</span>
          </div>
        <CollapseIcon id="all" open={hideDetail.all} onClick={hideListDetail} />
      </ListHeader>
      <FlexBox enableMediaQuery={true} fd="row" style={{ minHeight: '120px' }}>
        <GetCompleteList markCurrentItem={true} taskList={allList} setItem={setItem} setTimerOn={setTimerOn} hideDetail={hideDetail.all} deleteItem={deleteItem} grouped={group.all} />
      </FlexBox>
      <Footer {...footerProps} />
    </div>
  );
}

export default Master;
