const { LOCALHOST } = require("./util");

const BASE_URL = `${LOCALHOST}/my-todo`;
const FILTER_URL = `${BASE_URL}/filter`;

const postListToBackend = async (body) => {
    let params = (new URL(document.location)).searchParams;
    let endDate = params.get("endDate");
    let tags = params.get("tags");
    let response = [];
    // if (endDate || tags) {
    //     response = await fetch(FILTER_URL, {
    //         method: 'POST',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify(body)
    //     });
    // } else {
        response = await fetch(BASE_URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });
    // }
    return response;
}

const getListFromBackend = async () => {
    let params = (new URL(document.location)).searchParams;
    let endDate = params.get("endDate");
    let response = [];
    // if(endDate) {
    //     response = await fetch(`${FILTER_URL}?endDate=${endDate}`).then(resp => resp.json());
    // } else {
        response = await fetch(BASE_URL).then(resp => resp.json());
    // }
    
    return response;
}

const doLoginIn = async ({ username, password }) => {
    const response = await fetch(`${BASE_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
    });
    
    return response;
}

export default {
    getListFromBackend, postListToBackend, FILTER_URL, BASE_URL, doLoginIn
}