// CalendarComponent.tsx
import React, { useState, useCallback } from "react";
import {
  Calendar,
  dateFnsLocalizer,
  Views,
  Event as CalendarEvent,
} from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

import CalendarCustomEvent from "./CalenderCustomEvent";
import TaskModal from "./TaskModal";
import DraggableTask from "./DraggableTask";
import UnscheduledPanel from "./UnscheduledPanel";


const locales = {
  "en-US": require("date-fns/locale/en-US"),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
  getDay,
  locales,
});

const DnDCalendar = withDragAndDrop(Calendar, {
  dragFromOutsideItem: () => null, // we will override this in props
  onDropFromOutside: () => {},     // we will override this in props
});


const workDayStartHour = 6;
const workDayEndHour = 23;

const mapTasksToEvents = (tasks) => {
    return tasks.map((task) => {
      const defaultDurationMinutes = parseInt(task.timeFrame || "60", 10);
      const startDate = new Date(task.start || Date.now());
      const endDate = new Date(task.end || Date.now());
  
      return {
        ...task,
        start: startDate,
        end: endDate,
      };
    });
};
  
const CalendarComponent = ({ list, setItem, setTimerOn, deleteItem, setShowForm }) => {  
  const events = mapTasksToEvents(list);
  const unscheduledEvents = events.filter(t => t.current === true);

  const [selectedEventId, setSelectedEventId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const externalTaskRef = React.useRef(null);

  const getExternalTask = useCallback(() => {
    return externalTaskRef.current;
  }, []);  

  const moveEvent = useCallback(({ event, start, end }) => {
    const newStart = start.toISOString()
    const newEnd = end.toISOString()
    setItem(event.id, { start: newStart, end: newEnd })
  }, [events]);

  const handleSelectEvent = (event) => {
    setSelectedEventId(event.id);
    setIsModalOpen(true);
  };

  const handleSelectSlot = ({ start, end }) => {
    setShowForm(true)
  };

  const handleDropFromOutside = ({ start, end }) => {
    const task = externalTaskRef.current;
    console.log("handleDropFromOutside", task, start, end);
    if (task) {
      moveEvent({ event: task, start, end });
      externalTaskRef.current = null;
    }
  };

  console.log('externalTaskRef', externalTaskRef)
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="p-4">
        <div style={{ width: "100vh", margin: '10px 40px' }}>
          <h3 className="font-bold mb-2">Unscheduled Tasks</h3>
          <UnscheduledPanel tasks={unscheduledEvents} onDragStart={(event) => {
                console.log("onDragStart called");
                externalTaskRef.current = event;
            }} />
        </div>
        <h2 className="text-xl font-bold mb-4">Task Calendar</h2>
        <DnDCalendar
          min={new Date(1970, 1, 1, workDayStartHour)}
          max={new Date(1970, 1, 1, workDayEndHour)}
          scrollToTime={new Date(1970, 1, 1, workDayStartHour)}
          localizer={localizer}
          events={events}
          onEventDrop={moveEvent}
          onEventResize={moveEvent}
          onSelectEvent={handleSelectEvent}
          selectable
          onSelectSlot={handleSelectSlot}
          defaultView={Views.WEEK}
          droppableAccessor={() => true}
          views={[Views.MONTH, Views.WEEK, Views.DAY]}
          style={{ height: "80vh" }}
          onDropFromOutside={handleDropFromOutside}
          dragFromOutsideItem={getExternalTask}
          eventPropGetter={(event) => {
            const backgroundColor = event.checked ? "green" : "#3174ad";
            return {
              style: {
                backgroundColor,
              },
            };
          }}
        />
        <TaskModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            task={events.find(evt => selectedEventId === evt.id)}
            setItem={setItem}
            setTimerOn={setTimerOn}
            deleteItem={deleteItem}
            markCurrentItem={false}
            grouped={false}
        />
      </div>
    </DndProvider>
  );
};

export default CalendarComponent;
