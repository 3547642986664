import * as React from 'react';
import moment from 'moment';
import { Checkbox } from '@mui/material';
import { CustomButton, FlexBox, ItemContainer, ListTextBlock, PriorityBlock, DoubleClickEditField, Hr, AlignButton, AlignSchedule, TaskHeading, getReadableTimeFrame, DoubleClickDropDown, ImpactDropDown } from '../styles';
import { getGroupColor, isCurrent, isBacklog, isFollow, isDone, pageType, isMarkedMaster, isPage, getTaskColor, crossedDeadline, DEADLINE, ITEM_TYPE } from '../util';
import { playNotification } from './Notification';
import { DoubleClickDateChange } from '../styles';
import { useNavigate } from 'react-router-dom';
import MaterialIcon from './MaterialIcon';

const getNextDay = (deadline) => {
  return moment(deadline).set({ hour: 23, minute: 0 }).add(1, 'days')
}

const ExtendDeadlineButton = ({ item, setItem }) => {
  return <CustomButton label="Add one day" bg={'transparent'} color='success' variant="outlined" onClick={() => {
    const deadlineType = crossedDeadline(item);
    let newItem = item;
    if (deadlineType === DEADLINE.FINAL) {
      newItem = { ...item, finalDeadline: getNextDay(item.finalDeadline), tempDeadline: getNextDay(item.tempDeadline) }
    } else {
      newItem = { ...item, tempDeadline: getNextDay(item.tempDeadline) }
    }
    setItem(item.id, newItem);
  }}
    text={<MaterialIcon type="nextdate" />}
  />
}

const CustomListItem = ({ markCurrentItem, item, setItem, setTimerOn, hideDetail, deleteItem, grouped }) => {
  const {
    id,
    title,
    timeFrame,
    checked,
    weightage,
    relativeOrder,
    impact,
    tempDeadline,
    finalDeadline,
    start,
    end,
    followUpName,
    nextAction,
    parentTag,
    firstTask
  } = item;
  const listTextFinalStyle = checked ? { 'text-decoration': 'line-through' } : {};
  const readableTimeFrame = getReadableTimeFrame(timeFrame);
  const navigate = useNavigate();

  const addAction = (id) => {
    setItem(id, { current: true, checked: false, backlog: false, follow: false });
  }

  const followupAction = (id) => {
    setItem(id, { current: false, checked: false, backlog: false, follow: true });
  }

  const sideActionButtons = () => {
    return <FlexBox fd="column">
      <Checkbox
        checked={checked}
        onChange={(event) => {
          if (event.target.checked) {
            playNotification();
            setItem(item.id, { checked: event.target.checked, completedAt: (new Date().toISOString()), current: false, follow: false, backlog: false });
          } else {
            setItem(item.id, { checked: event.target.checked });
          }
        }}
        inputProps={{ 'aria-label': 'controlled' }}
        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
      />
      {!hideDetail &&
        <>
          {isPage(pageType.CURRENT) && <CustomButton variant="outlined" bg={'transparent'} color={isFollow(item) ? "darkseagreen" : "#D8BBAA"} onClick={() => {
            if (isFollow(item)) {
              addAction(id)
            } else {
              followupAction(id)
            }
          }} text={
            isFollow(item) ? <MaterialIcon type="current" /> : <MaterialIcon type="followup" />
          } label={
            isFollow(item) ? 'Move to current' : 'Move to followup'
          }
          />}
          {isPage(pageType.CURRENT) &&
            <CustomButton variant="outlined" bg={'transparent'} color={isDone(item) ? '#df3535eb' : (isBacklog(item) ? 'darkseagreen' : 'black')} onClick={() => {
              if (isDone(item)) {
                deleteItem(id);
              } else if (isBacklog(item)) {
                setItem(id, { current: true, follow: false });
              } else {
                setTimerOn({ taskId: id, duration: timeFrame });
                navigate(`/timer`);
              }
            }}
              text={isBacklog(item) ?
                <MaterialIcon type="current" /> :
                (isDone(item) ? <MaterialIcon type="delete" /> : <MaterialIcon type="play" />)
              }
              label={isBacklog(item) ?
                'Move to current' :
                (isDone(item) ? 'Delete this' : 'Start the time')
              }
            />}
          {isPage(pageType.CURRENT) &&
            <DoubleClickDropDown type={ITEM_TYPE.ORDER} inputs={new Array(101).fill(0).map((v, idx) => {
              if (idx === 100) return undefined;
              return idx + 1;
            })} value={relativeOrder} setValue={(order) => {
              setItem(item.id, { relativeOrder: order });
            }} />
          }
        </>}
    </FlexBox>
  }

  const bottomActionButtons = () => {
    return <AlignSchedule fd="row">
      <DoubleClickDropDown type={ITEM_TYPE.PRIORITY} inputs={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} value={weightage} setValue={(val) => {
        setItem(item.id, { weightage: val });
      }} />
      {hideDetail && parentTag && !grouped ? <PriorityBlock bg={getGroupColor(parentTag)}>{parentTag} </PriorityBlock> : null}
      <ImpactDropDown value={impact} setValue={(val) => {
        setItem(item.id, { impact: val });
      }} />
      <CustomButton bg={'transparent'} variant="outlined" color={isDone(item) ? '#df3535eb' : isCurrent(item) ? 'darkseagreen' : 'black'} onClick={() => {
        if (isDone(item)) {
          deleteItem(id);
        } else if (isBacklog(item)) {
          setItem(id, { current: true, follow: false });
        } else {
          setTimerOn({ taskId: id, duration: timeFrame });
          navigate(`/timer`);
        }
      }}
        text={isDone(item) ? <MaterialIcon type="delete" /> : (isBacklog(item) ? <MaterialIcon type="current" /> : <MaterialIcon type="play" />)}
        label={isDone(item) ? 'Delete this' : (isBacklog(item) ? 'Move to current' : 'Start the time')}
      />
      {((isCurrent(item) || isFollow(item))) &&
        <CustomButton bg={'transparent'} variant="outlined" color="secondary" onClick={() => {
          setItem(id, { current: false, checked: false, follow: false });
        }} 
        text={<MaterialIcon type="backlog" />}
        label={'Move to backlog'}
        />
      }
      {!isPage(pageType.CURRENT) && 
        <CustomButton bg={'transparent'} variant="outlined" color="secondary" onClick={() => {
          if (isMarkedMaster(item)) {
            setItem(id, { page: pageType.CURRENT });
          } else {
            setItem(id, { page: pageType.MASTER });
          }
        }}
        text={isMarkedMaster(item) ? <MaterialIcon type="current" /> : <MaterialIcon type="main" />}
        label={isMarkedMaster(item) ? 'Move to current' : 'Move to master'}
        />
      }
      <ExtendDeadlineButton item={item} setItem={setItem} />
      {isPage(pageType.MASTER) &&
        <CustomButton bg={'transparent'} variant="outlined" color="secondary" onClick={() => {
          setItem(id, { page: pageType.SOMEDAY });
        }}
        text={<MaterialIcon type="infinite" />}
        label={'Move to someday'}
        />
      }
    </AlignSchedule>
  }

  return <ItemContainer key={id} bc={getTaskColor(item, markCurrentItem, checked)} bg={grouped ? getGroupColor(item.parentTag) : null} fd="column">
    {(grouped && firstTask) ?
      <TaskHeading bg={getGroupColor(item.parentTag)}>{item.parentTag}</TaskHeading>
      : null
    }
    <FlexBox fd="row">
      <FlexBox fd="row">
        {sideActionButtons()}
      </FlexBox>
      <ListTextBlock fd="column" style={listTextFinalStyle}>
        <div>
          <DoubleClickEditField value={title} placeholder={"Task title?"} setValue={(val) => {
            setItem(item.id, { title: val });
          }} />
        </div>
        {<Hr />}
        {!hideDetail ? <div>
          <DoubleClickEditField labelStyle={{ whiteSpace: 'pre-wrap', color: (nextAction ? undefined : '#df3535eb'), fontSize: '14px' }} value={nextAction} label={"Next action: "} placeholder={"specific?"} setValue={(val) => {
            setItem(item.id, { nextAction: val });
          }} />
        </div> : null}
        {!hideDetail ? <Hr /> : null}
        {!hideDetail ? <div>
          <DoubleClickEditField labelStyle={{ whiteSpace: 'pre-wrap', color: (followUpName ? undefined : '#df3535eb'), fontSize: '14px' }} value={followUpName} label={"Followup: "} placeholder={" with?"} setValue={(val) => {
            setItem(item.id, { followUpName: val });
          }} />
        </div> : null}
        {!hideDetail ? <Hr /> : null}
        {!hideDetail &&
          <AlignSchedule fd="row">
            <DoubleClickEditField type={ITEM_TYPE.TIME_FRAME} icon={<MaterialIcon style={{ marginLeft: '5px' }} type="time" />} unit="number" width="60px" value={timeFrame} label={readableTimeFrame} setValue={(val) => {
              setItem(item.id, { timeFrame: val });
            }} />
            <DoubleClickDateChange text="Temporary" label="Assign temporary deadline" value={tempDeadline} setValue={(v) => {
              setItem(item.id, { tempDeadline: v });
            }} />
            <DoubleClickDateChange text="Final" label="Assign final deadline" color="white" bg="#df3535eb" value={finalDeadline} setValue={(v) => {
              setItem(item.id, { finalDeadline: v });
            }} />
            <DoubleClickDateChange text="Start" label="Assign start time" color="white" bg="green" value={start} setValue={(v) => {
              setItem(item.id, { start: v });
            }} />
            <DoubleClickDateChange text="End" label="Assign end time" color="white" bg="grey" value={end} setValue={(v) => {
              setItem(item.id, { end: v });
            }} />
          </AlignSchedule>}
        {hideDetail ? null : <Hr />}
        {!hideDetail && bottomActionButtons()}
      </ListTextBlock>
    </FlexBox>
  </ItemContainer>;
}

export default CustomListItem;