import React, { useState } from 'react';
import { Drawer, IconButton, Box, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DraggableTask from './DraggableTask';

const UnscheduledPanel = ({ tasks, onDragStart }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {/* Toggle Button */}
      <IconButton
        onClick={() => setOpen(true)}
        sx={{ position: 'absolute', top: 16, left: 16, zIndex: 1100 }}
      >
        <MenuIcon />
      </IconButton>

      {/* Drawer Panel */}
      <Drawer
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{ sx: { width: 280, padding: 2 } }}
      >
        <Typography variant="h6" gutterBottom>
          Unscheduled Tasks
        </Typography>
        {tasks.map((task) => (
          <DraggableTask key={task.id} task={task} onDragStart={onDragStart} />
        ))}
      </Drawer>
    </>
  );
};

export default UnscheduledPanel;
