import React, { useState } from 'react';
import network_service from '../network_service';

function LoginForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await network_service.doLoginIn({ username, password });
      console.log(response)
      if (response.status !== 200) {
        setError('Login failed');
        return;
      }

      console.log('Success:', response);
      setError('');

      window.location.reload()
    } catch (err) {
      setError('An error occurred during login.');
      console.error(err);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div>
        <label htmlFor="username">Username:</label>
        <input
          type="text"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
      </div>
      <div>
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>
      <button type="submit">Login</button>
      {}
    </form>
  );
}

export default LoginForm;