import * as React from 'react';
import { sortAndGroupList } from '../util';
import { FlexBox, ListHeader, ListContainer, getReadableTimeFrame } from '../styles';
import GetCompleteList from '../components/CompleteList';
import { GroupIcon, CollapseIcon } from '../components/ButtonAndIcon';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';

function NotesPage({
    list,
    group,
    postApi,
    timerOn,
    setTimerOn,
    minimize,
    hideDetail,
    addTaskToList,
    pageDate,
    changeDate,
    deleteItem,
    setItem,
    minimizeList,
    hideListDetail,
    groupTask,
    saveList,
    getList,
    showForm,
    setShowForm,
}) {
    const navigate = useNavigate();
    const quickChecklist = sortAndGroupList('quick_checklist', list, group.today);

    const totalQuickChecklistTime = getReadableTimeFrame(quickChecklist.reduce((total, t) => (Number(t.timeFrame) + total), 0));
    const footerProps = { addTaskToList, navigate, minimizeList, minimize, postApi, showForm, setShowForm };

    return (
        <div className="App">
            <FlexBox enableMediaQuery={true} fd="row" style={{ minHeight: '120px' }}>
                <ListContainer hide={minimize.today}>
                    <ListHeader id="all" onClick={minimizeList} bg="yellow">
                        <GroupIcon id="all" grouped={group.all} onClick={groupTask} />
                        Notes/Quick checklist ({totalQuickChecklistTime})
                        <CollapseIcon id="all" open={hideDetail.all} onClick={hideListDetail} />
                    </ListHeader>
                    <GetCompleteList key="all" taskList={quickChecklist} setItem={setItem} setTimerOn={setTimerOn} hideDetail={hideDetail.all} deleteItem={deleteItem} grouped={group.today} />
                </ListContainer>
            </FlexBox>
            <Footer {...footerProps} />
        </div>
    );
}

export default React.memo(NotesPage);