import * as React from 'react';
import moment from 'moment';
import { CreateTaskForm, MinimizeView, CustomButton, ListHeader, FlexBox } from '../styles';
import { Snackbar } from '@mui/material';

const Footer = ({
    addTaskToList, navigate, minimizeList, minimize, postApi, month, week, showForm, setShowForm
}) => {
    const [open, setOpen] = React.useState(false);

    const nextMonth = () => {
        const m = moment().month(month).add(1, 'M').month()
        return m === 0 ? 12 : m;
    }

    const prevMonth = () => {
        const m = moment().month(month).subtract(1, 'M').month()
        return m === 0 ? 12 : m;
    }


    const handleSnackbarClick = () => {
        setOpen(true);
    };

    const handleSnackbarClose = (
        event,
        reason
    ) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    };

    return <>
        <CreateTaskForm onSave={addTaskToList} showForm={showForm} setShowForm={setShowForm} handleSnackbarClick={handleSnackbarClick}  />
        <MinimizeView>
            <>
                {minimize.today && <ListHeader id="today" onClick={minimizeList} bg="#ddee90">Today's todo</ListHeader>}
                {minimize.follow && <ListHeader id="follow" onClick={minimizeList} bg="#ae0000" color="white">Follow-up todo</ListHeader>}
                {minimize.done && <ListHeader id="done" onClick={minimizeList}>Done todo</ListHeader>}
                {minimize.backlog && <ListHeader id="backlog" bg="#90c0ee" onClick={minimizeList}>Backlog todo</ListHeader>}
            </>
            <div>
                {postApi.loading && <CustomButton maxWidth="75px" variant="contained" color="lightblue" text="Loading..." />}
                {postApi.success && <CustomButton maxWidth="75px" variant="contained" color="green" text="Saved!" />}
                {(postApi.success === false) && <CustomButton maxWidth="75px" variant="contained" color="red" text="Failed...!" />}
            </div>
            <div>
                <CustomButton onClick={() => setShowForm(!showForm)} text="Show Form" maxWidth="100px" />
                <CustomButton onClick={() => {
                    navigate(`/my-todo/logout`)
                }}
                    text={"Logout"}
                    maxWidth="120px"
                />
            </div>
            <FlexBox fd="row">
                <div>

                    <CustomButton onClick={() => {
                        navigate(`/week/${moment().format('w')}`)
                    }}
                        text={"Week View"}
                        maxWidth="120px"
                    />
                    <CustomButton onClick={() => {
                        navigate(`/week/${moment().week(week).add(1, 'week').week()}`)
                    }}
                        text={"Next Week"}
                        maxWidth="120px"
                    />
                    <CustomButton onClick={() => {
                        navigate(`/week/${moment().week(week).subtract(1, 'week').week()}`)
                    }}
                        text={"Prev Week"}
                        maxWidth="120px"
                    />
                </div>
                <div>
                    <CustomButton onClick={() => {
                        navigate(`/month/${moment().month() + 1}`)
                    }}
                        text={"Month View"}
                        maxWidth="120px"
                    />
                    <CustomButton onClick={() => {
                        navigate(`/month/${nextMonth()}`)
                    }}
                        text={"Next Month"}
                        maxWidth="120px"
                    />
                    <CustomButton onClick={() => {
                        navigate(`/month/${prevMonth()}`)
                    }}
                        text={"Prev Month"}
                        maxWidth="120px"
                    />
                </div>
            </FlexBox>
            <div>
                <CustomButton onClick={() => {
                    navigate('/')
                }}
                    text={"Current list"}
                    maxWidth="120px"
                />
                <CustomButton onClick={() => {
                    navigate('/current-backlog')
                }}
                    text={"Current Backlog"}
                    maxWidth="170px"
                />
                <CustomButton onClick={() => {
                    navigate(`/notes`)
                }}
                    text={"Notes"}
                    maxWidth="120px"
                />
            </div>
            <div>
                <CustomButton onClick={() => {
                    navigate('/master')
                }}
                    text={"Master list"}
                    maxWidth="120px"
                />
                <CustomButton onClick={() => {
                    navigate('/someday')
                }}
                    text={"Someday list"}
                    maxWidth="120px"
                />
                <CustomButton onClick={() => {
                    navigate('/archived')
                }}
                    text={"Archived list"}
                    maxWidth="120px"
                />

            </div>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message="Task created"
            />
        </MinimizeView>
    </>
}

export default Footer;