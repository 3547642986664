import * as React from 'react';
import { sortAndGroupList } from '../util';
import { FlexBox, ListHeader, ListContainer, getReadableTimeFrame } from '../styles';
import GetCompleteList from '../components/CompleteList';
import { GroupIcon, CollapseIcon } from '../components/ButtonAndIcon';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';

function CurrentBacklog({
    list,
    group,
    postApi,
    timerOn,
    setTimerOn,
    minimize,
    hideDetail,
    addTaskToList,
    pageDate,
    changeDate,
    deleteItem,
    setItem,
    minimizeList,
    hideListDetail,
    groupTask,
    saveList,
    getList,
    showForm,
    setShowForm,
}) {
    const navigate = useNavigate();

    const backlogList = sortAndGroupList('backlog', list, group.backlog);
    const doneList = sortAndGroupList('done', list, group.done);

    const totalBacklogTime = getReadableTimeFrame(backlogList.reduce((total, t) => (Number(t.timeFrame) + total), 0));
    const totalDoneTime = getReadableTimeFrame(doneList.reduce((total, t) => (Number(t.timeFrame) + total), 0));
    const footerProps = { addTaskToList, navigate, minimizeList, minimize, postApi, showForm, setShowForm };

    return (
        <div className="App">
            <FlexBox enableMediaQuery={true} fd="row" style={{ minHeight: '120px' }}>
                <ListContainer hide={minimize.backlog}>
                    <ListHeader id="all" bg="#90c0ee" onClick={minimizeList}>
                        <GroupIcon id="all" grouped={group.all} onClick={groupTask} />
                        Backlog todo ({totalBacklogTime})
                        <CollapseIcon id="all" open={hideDetail.all} onClick={hideListDetail} />
                    </ListHeader>
                    <GetCompleteList key="backlog" taskList={backlogList} setItem={setItem} setTimerOn={setTimerOn} hideDetail={hideDetail.all} deleteItem={deleteItem} grouped={group.backlog} />
                </ListContainer>
                <ListContainer hide={minimize.done}>
                    <ListHeader id="all" onClick={minimizeList}>
                        <GroupIcon id="all" grouped={group.all} onClick={groupTask} />
                        Done todo ({totalDoneTime})
                        <CollapseIcon id="all" open={hideDetail.all} onClick={hideListDetail} />
                    </ListHeader>
                    <GetCompleteList key="done" taskList={doneList} setItem={setItem} setTimerOn={setTimerOn} hideDetail={hideDetail.all} deleteItem={deleteItem} grouped={group.done} />
                </ListContainer>
            </FlexBox>
            <Footer {...footerProps} />
        </div>
    );
}

export default React.memo(CurrentBacklog);
