import * as React from 'react';
import { TimeBox, Spacer, CustomButton, TimeBlock, DoubleClickEditField, Hr } from '../styles';
import { playSound, pauseSound } from '../components/Notification';
import { useNavigate } from 'react-router-dom';

// countDownRenderer callback with condition
const CountDownRenderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    playSound();
    return <>
      <Spacer space="60px" />
      <TimeBox fd="row">You are good to go!</TimeBox>
      <Spacer space="60px" />
    </>;
  } else {
    return <div>
      <Spacer space="50px" />
      <TimeBox fd="row">
        <TimeBlock>{hours} hr</TimeBlock>:<TimeBlock>{minutes} mins</TimeBlock>:<TimeBlock>{seconds} s</TimeBlock>
      </TimeBox>
      <Spacer space="50px" />
    </div>;
  }
}


const Timer = ({ timerOn, setTimerOn, setItem, list }) => {
  const navigate = useNavigate();
  const task = list.find((tsk) => tsk.id === timerOn.taskId)
  const [time, setTime] = React.useState(Number(timerOn.duration) * 60);
  const [pause, setPaused] = React.useState(false);
  const [completed, setCompleted] = React.useState(false);
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time - (hours * 3600)) / 60);
  const seconds = Math.floor(time - (((hours * 60) + minutes) * 60));

  React.useEffect(() => {
    let sT = null;
    if (!pause) {
      if (time > 0) {
        sT = setTimeout(() => setTime(time - 1), 1000);
      } else {
        setCompleted(true);
      }
    }
    return () => clearTimeout(sT);
  }, [time, pause])

  return <div className="App">
    <div style={{ marginLeft: '20%', marginRight: '20%' }}>
      <CountDownRenderer hours={hours} minutes={minutes} seconds={seconds} completed={completed} />
      <Spacer space="30px" />
      <TimeBox fd="column">
        <Hr />
        <DoubleClickEditField value={task.title} placeholder={"Task title?"} setValue={(val) => {
          setItem(timerOn.taskId, { title: val });
        }} />
        <Hr />
        <DoubleClickEditField labelStyle={{ whiteSpace: 'pre-wrap', color: (task.nextAction ? undefined : 'red'), fontSize: '14px' }} value={task.nextAction} label={"Next action: "} placeholder={"specific?"} setValue={(val) => {
          setItem(timerOn.taskId, { nextAction: val });
        }} />
        <Hr />
        <DoubleClickEditField labelStyle={{ whiteSpace: 'pre-wrap', color: (task.followUpName ? undefined : 'red'), fontSize: '14px' }} value={task.followUpName} label={"Followup: "} placeholder={" with?"} setValue={(val) => {
          setItem(timerOn.taskId, { followUpName: val });
        }} />
        <Hr />
        <Spacer space="50px" />
      </TimeBox>
      <TimeBox fd="row">
        <CustomButton maxWidth="60px" onClick={playSound} text="Play" />
        <CustomButton maxWidth="60px" color={pause ? "success" : "error"} onClick={() => {
          setPaused(!pause);
          pauseSound();
        }} text={pause ? "Resume" : "Pause"} />
        <CustomButton maxWidth="60px" color="error"
          onClick={() => {
            pauseSound()
            setTime(Number(timerOn.duration) * 60);
          }} text="Reset" />
        <CustomButton color="success"
          maxWidth="75px"
          onClick={() => {
            pauseSound()
            setItem(timerOn.taskId, { checked: true, completedAt: (new Date().toISOString()), current: false, follow: false, backlog: false });
            setTimerOn(null)
            navigate(`/`);
          }} text="Complete" />
        <CustomButton maxWidth="80px" color="secondary"
          onClick={() => {
            pauseSound()
            setTimerOn(null)
            navigate(`/`);
          }} text="Go back" />
      </TimeBox>
      <TimeBox fd="row">
        {[
          { adTime: (1 / 6), name: "+10s" },
          { adTime: 1, name: "+1" },
          { adTime: 2, name: "+2" },
          { adTime: 5, name: "+5" },
          { adTime: 10, name: "+10" }
        ].map(meta => {
          return <CustomButton variant="" maxWidth="60px" onClick={() => {
            setTime(time + (meta.adTime * 60));
            setItem(timerOn.taskId, { timeFrame: Number(task.timeFrame) + meta.adTime });
          }} text={meta.name} />
        })}
      </TimeBox>
      <TimeBox fd="row">
        {[
          { adTime: (1 / 6), name: "-10s" },
          { adTime: 1, name: "-1" },
          { adTime: 2, name: "-2" },
          { adTime: 5, name: "-5" },
          { adTime: 10, name: "-10" }
        ].map(meta => {
          return <CustomButton variant="" color="error" maxWidth="60px" onClick={() => {
            setTime(time - (meta.adTime * 60));
            if(meta.adTime > 1)
              setItem(timerOn.taskId, { timeFrame: Number(task.timeFrame) - meta.adTime });
          }} text={meta.title} />
        })}
      </TimeBox>
    </div>
  </div>
}

export default Timer;