// components/TaskModal.tsx
import React from "react";
import Modal from "react-modal";
import CustomListItem from "./CustomListItem";
Modal.setAppElement('#root'); // Or whatever your app root div ID is

const TaskModal = ({
  isOpen,
  onClose,
  task,
  setItem,
  markCurrentItem,
  setTimerOn,
  deleteItem,
  grouped = false,
}) => {
  if (!task) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Task Details"
      overlayClassName="ReactModal__Overlay"
      className="ReactModal__Content"
    >
      <div className="mb-4 flex justify-between items-center">
        <h2 className="text-xl font-bold">Task Details</h2>
        <button onClick={onClose} className="text-gray-500 hover:text-red-500">
          ✕
        </button>
      </div>

      {task && (
        <CustomListItem
          item={task}
          markCurrentItem={markCurrentItem}
          setItem={setItem}
          setTimerOn={setTimerOn}
          hideDetail={false}
          deleteItem={deleteItem}
          grouped={grouped}
        />
      )}
    </Modal>
  );
};

export default TaskModal;
