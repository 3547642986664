import React from "react";
import { useDrag } from "react-dnd";

const DraggableTask = ({ task, onDragStart }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "event",
    item: () => {
      onDragStart(task);
      return task;
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        margin: "5px",
        padding: "3px",
        borderRadius: "12px",
        backgroundColor: task.checked ? "#4ade80" : "#facc15", // green or yellow
        color: "#1f2937", // dark text
        borderLeft: "12px solid #eab308", // thicker accent stripe
        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
        opacity: isDragging ? 0.4 : 1,
        cursor: "move",
        transition: "opacity 0.2s",
      }}
    >
      <div style={{ fontWeight: 600 }}>{task.title}</div>
      {task.nextAction && (
        <div style={{ fontSize: "0.85rem", color: "#374151", marginTop: "2px" }}>
          {task.nextAction}
        </div>
      )}
    </div>
  );
};

export default DraggableTask;


